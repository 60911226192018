import React, { useEffect, useState } from 'react';
import FundingQuestion from './FundingQuestion.jsx';
import BusinessNameForm from './BusinessNameForm.jsx';
import LegalStructureForm from './LegalStructureForm.jsx';
import BankAccountTypeForm from './BankAccountTypeForm.jsx';
import BusinessDurationForm from './BusinessDurationForm.jsx';
import FirstNameForm from './FirstNameForm.jsx';
import LastNameForm from './LastNameForm.jsx';
import FundUtilizationForm from './FundUtilizationForm.jsx';
import MonthlyRevenueForm from './MonthlyRevenueForm.jsx';
import FicoScoreForm from './FicoScoreForm.jsx';
import IndustryQuestion from './IndustryQuestion.jsx';
import ContactForm from './ContactForm.jsx';
import BankStatementUpload from './BankStatementUpload.jsx';
import DateOfBirthForm from './DateOfBirthForm.jsx';
import SsnForm from './SsnForm.jsx';
import PersonalInfoForm from './PersonalInfoForm.jsx';
import OwnershipForm from './OwnershipForm.jsx';
import OwnershipPercentageForm from './OwnershipPercentageForm.jsx';
import CompanyInfoForm from './CompanyInfoForm.jsx';
import LiensJudgmentsForm from './LiensJudgmentsForm.jsx';
import DefaultCashAdvanceForm from './DefaultCashAdvanceForm.jsx';
import BankruptcyForm from './BankruptcyForm.jsx';
import CurrentCashAdvanceForm from './CurrentCashAdvanceForm.jsx';
import LenderNameForm from './LenderNameForm.jsx';
import CurrentBalanceForm from './CurrentBalanceForm.jsx';
import PaymentFrequencyForm from './PaymentFrequencyForm.jsx';
import PaymentAmountForm from './PaymentAmountForm.jsx';
import CollateralForm from './CollateralForm.jsx';
import PositionForm from './PositionForm.jsx';
import PropertyAddressForm from './PropertyAddressForm.jsx';
import AssetTypeForm from './AssetTypeForm.jsx';
import PropertyAcquisitionYearForm from './PropertyAcquisitionYearForm.jsx';
import PurchasePriceForm from './PurchasePriceForm.jsx';
import PropertyValueForm from './PropertyValueForm.jsx';
import LoanBalanceForm from './LoanBalanceForm.jsx';
import OtherLendersForm from './OtherLendersForm.jsx';
import LenderNamesForm from './LenderNamesForm.jsx';
import TitleHoldersForm from './TitleHoldersForm.jsx';
import useFormState from '../hooks/useFormState.jsx';
import ProgressBars from './ProgressBars';
import useSurveyStore from '../store/useSurveyStore.js';
import Fundo from "./Fundo.jsx";

const QuestionFlow = () => {
    const [state, updateState, isQualifiedLead] = useFormState();
    const [currentStep, setCurrentStep] = useState(1);
    const { percentageProcess, updatePercentageProcess } = useSurveyStore();
    const goNext = () => setCurrentStep(prevStep => prevStep + 1);
    const goBack = () => setCurrentStep(prevStep => prevStep - 1);

    const formSteps = [
        {
            step: 1,
            component: FundingQuestion,
            props: {
                onGatingUtmContact: (value) => { updateState('utmContent')(value) },
                onGatingUtmMedium: (value) => { updateState('utmMedium')(value) },
                onSelect: (value) => {
                    updateState('desiredFunding')(value);
                    goNext();
                },
            },
        },
        {
            step: 2,
            component: BusinessNameForm,
            props: {
                fundingAmount: state.desiredFunding,
                onSubmit: (value) => {
                    updateState('officialBusinessName')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('desiredFunding')(null);
                    goBack();
                },
            },
        },
        {
            step: 3,
            component: LegalStructureForm,
            props: {
                companyName: state.officialBusinessName,
                onSubmit: (value) => {
                    updateState('legalStructure')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('officialBusinessName')(null);
                    goBack();
                },
            },
        },
        {
            step: 4,
            component: BankAccountTypeForm,
            props: {
                companyName: state.officialBusinessName,
                onSubmit: (value) => {
                    updateState('typeOfBankAccount')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('legalStructure')(null);
                    goBack();
                },
            },
        },
        {
            step: 5,
            component: BusinessDurationForm,
            props: {
                companyName: state.officialBusinessName,
                onSelect: (value) => {
                    updateState('businessDuration')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('typeOfBankAccount')(null);
                    goBack();
                },
            },
        },
        {
            step: 6,
            component: FirstNameForm,
            props: {
                companyName: state.officialBusinessName,
                onSubmit: (value) => {
                    updateState('firstName')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('businessDuration')(null);
                    goBack();
                },
            },
        },
        {
            step: 7,
            component: LastNameForm,
            props: {
                firstName: state.firstName,
                onSubmit: (value) => {
                    updateState('lastName')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('firstName')(null);
                    goBack();
                },
            },
        },
        {
            step: 8,
            component: FundUtilizationForm,
            props: {
                firstName: state.firstName,
                onSubmit: (value) => {
                    updateState('utilizationOfFunds')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('lastName')(null);
                    goBack();
                },
            },
        },
        {
            step: 9,
            component: MonthlyRevenueForm,
            props: {
                companyName: state.officialBusinessName,
                onSubmit: (value) => {
                    updateState('monthlyRevenue')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('utilizationOfFunds')(null);
                    goBack();
                },
            },
        },
        {
            step: 10,
            component: FicoScoreForm,
            props: {
                firstName: state.firstName,
                onSubmit: (value) => {
                    updateState('ficoScore')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('monthlyRevenue')(null);
                    goBack();
                },
            },
        },
        {
            step: 11,
            component: IndustryQuestion,
            props: {
                onSelect: (value) => {
                    updateState('industry')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('ficoScore')(null);
                    goBack();
                },
            },
        },
        {
            step: 12,
            component: ContactForm,
            props: {
                firstName: state.firstName,
                onSubmit: ({ phoneNumber, email }) => {
                    updateState('phoneNumber')(phoneNumber);
                    updateState('email')(email);
                    goNext();
                },
                onGoBack: () => {
                    updateState('industry')(null);
                    goBack();
                },
            },
        },
        {
            step: 13,

            component: isQualifiedLead ? BankStatementUpload : Fundo,
            props: {
                userEmail: state.email,
                onSubmit: (value) => {
                    updateState('bankStatements')(value);
                    goNext();
                },
                onGoBack: () => {
                    // updateState('phoneNumberAndEmail')(null);
                    updateState('phoneNumber')(null);
                    updateState('email')(null);
                    goBack();
                },
            },
        },
        {
            step: 14,
            component: DateOfBirthForm,
            props: {
                onSubmit: (value) => {
                    updateState('dateOfBirth')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('bankStatements')(null);
                    goBack();
                },
            },
        },
        {
            step: 15,
            component: SsnForm,
            props: {
                onSubmit: (value) => {
                    updateState('socialSecurityNumber')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('dateOfBirth')(null);
                    goBack();
                },
            },
        },
        {
            step: 16,
            component: PersonalInfoForm,
            props: {
                onSubmit: (value) => {
                    updateState('homeAddress')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('socialSecurityNumber')(null);
                    goBack();
                },
            },
        },
        {
            step: 17,
            component: OwnershipForm,
            props: {
                onSubmit: (value) => {
                    updateState('owns51Percent')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('homeAddress')(null);
                    goBack();
                },
            },
        },
        {
            step: 18,
            component: OwnershipPercentageForm,
            props: {
                onSubmit: (value) => {
                    updateState('ownershipPercentage')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('owns51Percent')(null);
                    goBack();
                },
            },
        },
        {
            step: 19,
            component: CompanyInfoForm,
            props: {
                onSubmit: (value) => {
                    updateState('businessInfo')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('ownershipPercentage')(null);
                    goBack();
                },
            },
        },
        {
            step: 20,
            component: LiensJudgmentsForm,
            props: {
                onSubmit: (value) => {
                    updateState('liensOrJudgments')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('businessInfo')(null);
                    goBack();
                },
            },
        },
        {
            step: 21,
            component: DefaultCashAdvanceForm,
            props: {
                onSubmit: (value) => {
                    updateState('defaultedCashAdvance')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('liensOrJudgments')(null);
                    goBack();
                },
            },
        },
        {
            step: 22,
            component: BankruptcyForm,
            props: {
                onSubmit: (value) => {
                    updateState('filedBankruptcy')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('defaultedCashAdvance')(null);
                    goBack();
                },
            },
        },
        {
            step: 23,
            component: CurrentCashAdvanceForm,
            props: {
                onSubmit: (value) => {
                    updateState('currentCashAdvance')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('filedBankruptcy')(null);
                    goBack();
                },
            },
        },
        {
            step: 24,
            component: LenderNameForm,
            props: {
                onSubmit: (value) => {
                    updateState('lenderName')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('currentCashAdvance')(null);
                    goBack();
                },
            },
        },
        {
            step: 25,
            component: CurrentBalanceForm,
            props: {
                onSubmit: (value) => {
                    updateState('currentBalance')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('lenderName')(null);
                    goBack();
                },
            },
        },
        {
            step: 26,
            component: PaymentFrequencyForm,
            props: {
                onSubmit: (value) => {
                    updateState('paymentFrequency')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('currentBalance')(null);
                    goBack();
                },
            },
        },
        {
            step: 27,
            component: PaymentAmountForm,
            props: {
                onSubmit: (value) => {
                    updateState('paymentAmount')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('paymentFrequency')(null);
                    goBack();
                },
            },
        },
        {
            step: 28,
            component: CollateralForm,
            props: {
                onSubmit: (value) => {
                    updateState('hasCollateral')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('paymentAmount')(null);
                    goBack();
                },
            },
        },
        {
            step: 29,
            component: PositionForm,
            props: {
                onSubmit: (value) => {
                    updateState('position1')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('hasCollateral')(null);
                    goBack();
                },
            },
        },
        {
            step: 30,
            component: PropertyAddressForm,
            props: {
                onSubmit: (value) => {
                    updateState('propertyAddress')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('position1')(null);
                    goBack();
                },
            },
        },
        {
            step: 31,
            component: AssetTypeForm,
            props: {
                onSubmit: (value) => {
                    updateState('assetType')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('propertyAddress')(null);
                    goBack();
                },
            },
        },
        {
            step: 32,
            component: PropertyAcquisitionYearForm,
            props: {
                onSubmit: (value) => {
                    updateState('yearAcquired')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('assetType')(null);
                    goBack();
                },
            },
        },
        {
            step: 33,
            component: PurchasePriceForm,
            props: {
                onSubmit: (value) => {
                    updateState('purchasePrice')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('yearAcquired')(null);
                    goBack();
                },
            },
        },
        {
            step: 34,
            component: PropertyValueForm,
            props: {
                onSubmit: (value) => {
                    updateState('currentValue')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('purchasePrice')(null);
                    goBack();
                },
            },
        },
        {
            step: 35,
            component: LoanBalanceForm,
            props: {
                onSubmit: (value) => {
                    updateState('loanBalance')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('currentValue')(null);
                    goBack();
                },
            },
        },
        {
            step: 36,
            component: OtherLendersForm,
            props: {
                onSubmit: (value) => {
                    updateState('otherLenders')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('loanBalance')(null);
                    goBack();
                },
            },
        },
        {
            step: 37,
            component: LenderNamesForm,
            props: {
                onSubmit: (value) => {
                    updateState('lenderName1')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('otherLenders')(null);
                    goBack();
                },
            },
        },
        {
            step: 38,
            component: TitleHoldersForm,
            props: {
                onSubmit: (value) => {
                    updateState('titleHolders')(value);
                    goNext();
                },
                onGoBack: () => {
                    updateState('lenderName1')(null);
                    goBack();
                },
            },
        },
    ];

    const renderCurrentForm = () => {
        const currentFormStep = formSteps.find(step => step.step === currentStep);
        if (currentFormStep) {
            const { component: Component, props } = currentFormStep;
            return <Component {...props} />;
        }
        return null;
    };
    useEffect(() => {
        updatePercentageProcess(state);
    }, [state]);

    return (
        <div className="flex flex-col justify-center items-center min-h-screen w-full">
            {renderCurrentForm()}
            <ProgressBars value={percentageProcess} />
        </div>
    );
};

export default QuestionFlow;
