import React, { useEffect } from 'react';

const BankStatementUpload = ({ onSubmit }) => {
    useEffect(() => {
        // Function to send the height and width to the parent window
        function sendHeightAndWidth() {
            window.parent.postMessage({ location: 'funnel-bank-statement' }, "*");
        }

        sendHeightAndWidth();
        window.addEventListener('resize', sendHeightAndWidth);

        return () => {
            window.removeEventListener('resize', sendHeightAndWidth);
        };
    }, []);
    return (
        <div>loading...</div>
    );
};
export default BankStatementUpload;
// import React, { useState } from 'react';
// import axios from 'axios';
// import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

// const BankStatementUpload = ({ locationId = 'RKQ29EXfnTzKB5PN9er1', contactId, maxFiles = '4' }) => {
//     const [files, setFiles] = useState({
//         firstMonth: null,
//         secondMonth: null,
//         thirdMonth: null,
//         fourthMonth: null,
//     });

//     const handleFileChange = (event, month) => {
//         const file = event.target.files[0];
//         if (file && isValidFileType(file)) {
//             setFiles((prevFiles) => ({
//                 ...prevFiles,
//                 [month]: file,
//             }));
//         } else {
//             alert('Please upload a valid file (image, PDF, or DOC).');
//         }
//     };

//     const isValidFileType = (file) => {
//         const validTypes = [
//             'image/jpeg',
//             'image/png',
//             'application/pdf',
//             'application/msword',
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//         ];
//         return validTypes.includes(file.type);
//     };

//     const handleUpload = async () => {
//         if (!files.firstMonth) {
//             alert('Please upload the Bank Statement for the 1st Month.');
//             return;
//         }

//         const formData = new FormData();
//         Object.keys(files).forEach(month => {
//             if (files[month]) {
//                 formData.append(month, files[month]);
//             }
//         });
//         formData.append('id', contactId);  // Add the Contact/Opportunity/Custom Field ID
//         formData.append('maxFiles', maxFiles);  // Add the maximum number of files allowed

//         try {
//             // Webhooks => https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/33c26cda-a757-492f-99d7-f1db9d66d42e
//             const response = await axios.post(`https://services.leadconnectorhq.com/locations/${locationId}/customFields/upload`, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6IlJLUTI5RVhmblR6S0I1UE45ZXIxIiwidmVyc2lvbiI6MSwiaWF0IjoxNzE0NDc3MjIxODIzfQ.6DtuUQBnrT7bldg2QbWWFx1WPVR-ImJ3tk2MHo_emuw',  // Replace with actual token
//                     'Version': '2021-07-28',  // API version required by GoHighLevel
//                 },
//             });

//             console.log('Files successfully uploaded:', response.data);
//             alert('Files successfully uploaded!');
//         } catch (error) {
//             console.error('Error uploading files:', error);
//             alert('There was an error uploading the files. Please try again.');
//         }
//     };

//     const renderFileInput = (id, label, month) => (
//         <div className="text-left">
//             <label htmlFor={id} className="block text-sm font-medium text-gray-700">
//                 {label}
//             </label>
//             <label
//                 htmlFor={id}
//                 className={`cursor-pointer mt-1 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg h-24 ${files[month] ? 'bg-green-50 border-green-400' : ''}`}
//             >
//                 <input
//                     id={id}
//                     type="file"
//                     className="hidden"
//                     onChange={(e) => handleFileChange(e, month)}
//                     accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
//                 />
//                 <div className="text-center">
//                     {files[month] ? (
//                         <>
//                             <svg className="mx-auto h-8 w-8 text-green-600" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true">
//                                 <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
//                             </svg>
//                             <span className="text-sm text-green-600 truncate">{files[month].name}</span>
//                         </>
//                     ) : (
//                         <>
//                             <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true">
//                                 <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
//                             </svg>
//                             <span className="text-sm text-gray-500">{label}</span>
//                         </>
//                     )}
//                 </div>
//             </label>
//         </div>
//     );

//     return (
//         <div className="max-w-xl mx-auto p-6 bg-white text-center">
//             <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
//             <h1 className="text-2xl font-semibold mb-4">
//                 We Need You To Submit Your Last 4 Bank Statements
//             </h1>
//             <p className="mb-6 text-gray-700">
//                 To enhance your qualification for the cash advance, providing your bank statements will be beneficial.
//             </p>
//             <div className="flex flex-col gap-4">
//                 {renderFileInput('file-input-1', 'Bank Statement 1st Month *', 'firstMonth')}
//                 {renderFileInput('file-input-2', 'Bank Statement 2nd Month', 'secondMonth')}
//                 {renderFileInput('file-input-3', 'Bank Statement 3rd Month', 'thirdMonth')}
//                 {renderFileInput('file-input-4', 'Bank Statement 4th Month', 'fourthMonth')}
//             </div>
//             <button
//                 onClick={handleUpload}
//                 className="submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out mt-6 focus:outline-none"
//             >
//                 Submit
//             </button>
//             <div className="mt-6 text-sm">
//                 <a target="_blank" rel="noopener noreferrer"href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">
//                     Terms of use
//                 </a>{' '}
//                 ·{' '}
//                 <a target="_blank" rel="noopener noreferrer"href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline">
//                     Privacy policy
//                 </a>
//             </div>
//         </div>
//     );
// };

// export default BankStatementUpload;
