import React, { useMemo } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const IndustryQuestion = ({ onSelect, onGoBack }) => {
    const industries = useMemo(() => [
        "Advertising", "Agriculture", "Apparel", "Auto Repair", "Auto Sales", "Banking",
        "Biotechnology", "Business Services", "Chiropractor", "Church", "Cleaning / Janitorial",
        "Construction", "Consulting", "Convenience Store", "Dental", "E-Commerce", "Education",
        "Energy", "Engineering", "Entertainment", "Finance", "Gas Station", "Government Contractor",
        "Grocery", "Home Healthcare", "Hotel", "HVAC", "Insurance", "Jewelry", "Lawyer",
        "Manufacturing", "Medical Practice", "Nail Salon", "Not For Profit", "Optometrist", "Other",
        "Plumbing", "Real Estate", "Recreation", "Restaurant and Bar", "Retail", "Salon", "Service",
        "Shipping", "Staffing", "Technology", "Telecommunications", "Transportation and Trucking",
        "Veterinary", "Wholesale / Distributor"
    ], []);

    // Sort the industries alphabetically
    const sortedIndustries = useMemo(() => industries.sort(), [industries]);

    // Split the sorted industries into left and right columns
    const half = Math.ceil(sortedIndustries.length / 2);
    const leftColumn = sortedIndustries.slice(0, half);
    const rightColumn = sortedIndustries.slice(half);

    return (
        <div className="max-w-lg mx-auto p-6 bg-white text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                What Industry Are You In?
            </h1>
            <p className="mb-6 text-gray-700">
                Please select the industry that best describes your business. This information helps us provide you with the most relevant solutions.
            </p>
            <div className="max-h-96 overflow-y-auto grid grid-cols-2 gap-4">
                <div>
                    {leftColumn.map((industry, index) => (
                        <button
                            key={index}
                            className="submit-btn w-full submit-btn text-white py-2 px-4 mb-2 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                            onClick={() => onSelect(industry)}
                            aria-label={`Select ${industry}`}
                        >
                            {industry}
                        </button>
                    ))}
                </div>
                <div>
                    {rightColumn.map((industry, index) => (
                        <button
                            key={index}
                            className="submit-btn w-full submit-btn text-white py-2 px-4 mb-2 rounded-lg transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                            onClick={() => onSelect(industry)}
                            aria-label={`Select ${industry}`}
                        >
                            {industry}
                        </button>
                    ))}
                </div>
            </div>
            <button onClick={onGoBack} className="w-full py-2 px-4 mt-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none">
                Go Back
            </button>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default IndustryQuestion;
