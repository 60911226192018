// src/store/useSurveyStore.js
import create from 'zustand';

const useSurveyStore = create((set) => ({
    showSurvey: false,
    openSurvey: () => set({ showSurvey: true }),
    closeSurvey: () => set({ showSurvey: false }),

    // percentageProcess state and functions
    percentageProcess: 0,
    setPercentageProcess: (percentage) => set({ percentageProcess: percentage }),
    updatePercentageProcess: (state) => {
        const totalFields = Object.keys(state).length;
        const filledFields = Object.values(state).filter(value => value !== null && value !== '').length;
        const percentage = (filledFields / totalFields) * 100;
        set({ percentageProcess: percentage });
    },
}));

export default useSurveyStore;
