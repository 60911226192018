import { useState, useCallback, useEffect } from 'react';

const useFormState = () => {
    const [state, setState] = useState({
        desiredFunding: null,
        officialBusinessName: null,
        typeOfBankAccount: null,
        monthlyRevenue: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        dateOfBirth: null,
        ficoScore: null,
        socialSecurityNumber: null,
        homeAddress: null,
        utilizationOfFunds: null,
        legalStructure: null,
        owns51Percent: null,
        ownershipPercentage: null,
        businessInfo: null,
        liensOrJudgments: null,
        defaultedCashAdvance: null,
        filedBankruptcy: null,
        currentCashAdvance: null,
        lenderName: null,
        currentBalance: null,
        paymentFrequency: null,
        paymentAmount: null,
        hasCollateral: null,
        position1: null,
        propertyAddress: null,
        assetType: null,
        yearAcquired: null,
        purchasePrice: null,
        currentValue: null,
        loanBalance: null,
        otherLenders: null,
        lenderName1: null,
        titleHolders: null,
        bankStatements: null,
        utmContent: null,
        utmMedium: null,
        businessDuration: null,
    });

    const [isQualifiedLead, setIsQualifiedLead] = useState(null);
    const [pixelFired, setPixelFired] = useState({
        qualifiedLead: false,
        unqualifiedLead: false,
    });

    // const QualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/gJCqqV21hz7Xkgu7fn2L'; //! old webhooks
    // const UnQualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/XGJLfFqyZe232mGxa7j2'; //! old webhooks

    const QualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/be24d7b7-5376-4227-9b2e-17fb61428e44'
    const UnQualifiedLeadURL = 'https://services.leadconnectorhq.com/hooks/RKQ29EXfnTzKB5PN9er1/webhook-trigger/3c045058-6951-46b0-bf4c-001cf237d851'

    const determineQualification = useCallback(() => {
        const isQualified =
            state.typeOfBankAccount === 'business bank account' &&
            state.businessDuration !== 'Less than 6 months' &&
            state.businessDuration !== '6 - 12 months';
        setIsQualifiedLead(isQualified);
    }, [state.typeOfBankAccount, state.businessDuration]);

    useEffect(() => {
        determineQualification();
        if (!pixelFired.qualifiedLead && isQualifiedLead && state.phoneNumber && state.email && !state.businessInfo) {
            fireQualifiedLeadPixel();
        }

        if (!pixelFired.unqualifiedLead && !isQualifiedLead && state.phoneNumber && state.email) {
            fireUnqualifiedLeadPixel();
        }

    }, [state, isQualifiedLead, pixelFired]);

    const handleSubmit = useCallback(async (updatedState) => {
        const url = isQualifiedLead ? QualifiedLeadURL : UnQualifiedLeadURL;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedState),
            });
            // console.log('Sent this state:', updatedState);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }, [isQualifiedLead]);

    const fireQualifiedLeadPixel = () => {
        // Inject Google Tag Manager (noscript) iframe
        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');
        iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-M49SBV9Q";
        iframe.height = "0";
        iframe.width = "0";
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";
        noscript.appendChild(iframe);
        document.body.appendChild(noscript);

        // Ensure fbq is globally available
        window.fbq = window.fbq || function () {
            window.fbq.callMethod ?
                window.fbq.callMethod.apply(window.fbq, arguments) :
                window.fbq.queue.push(arguments)
        };
        if (!window._fbq) window._fbq = window.fbq;
        window.fbq.push = window.fbq;
        window.fbq.loaded = true;
        window.fbq.version = '2.0';
        window.fbq.queue = [];

        // Meta Pixel Code
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) :
                    n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        // eslint-disable-next-line no-undef
        fbq('init', '8242082332506292');
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead');

        // Append noscript fallback for Meta Pixel
        const noscriptElement = document.createElement('noscript');
        const imgElement = document.createElement('img');
        imgElement.height = "1";
        imgElement.width = "1";
        imgElement.style.display = "none";
        imgElement.src = "https://www.facebook.com/tr?id=8242082332506292&ev=Lead&noscript=1";
        noscriptElement.appendChild(imgElement);
        document.body.appendChild(noscriptElement);

        // Insert the conversion tracking script
        const script = document.createElement('script');
        script.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=lead";
        script.async = true;
        document.head.appendChild(script);

        // Mark this pixel as fired
        setPixelFired((prev) => ({ ...prev, qualifiedLead: true }));
    };

    const fireUnqualifiedLeadPixel = () => {
        const script = document.createElement('script');
        script.src = "https://enessedleasant.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&et=unqualified_lead";
        script.async = true;
        document.head.appendChild(script);

        // Mark this pixel as fired
        setPixelFired((prev) => ({ ...prev, unqualifiedLead: true }));
    };

    const updateState = (field) => (value) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    useEffect(() => {
        if ((state.phoneNumber && state.email) || state.bankStatements || state.currentCashAdvance || state.titleHolders) {
            handleSubmit(state);
        }
    }, [state, handleSubmit]);

    return [state, updateState, isQualifiedLead];
};

export default useFormState;
