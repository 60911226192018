import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const ContactForm = ({ firstName, onSubmit, onGoBack }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [agreedToCommunications, setAgreedToCommunications] = useState(false);
    const [errors, setErrors] = useState({});

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^(\+?1\s?)?(\([0-9]{3}\)|[0-9]{3})[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
        return phoneRegex.test(number);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phoneNumber') {
            setPhoneNumber(value);
            if (validatePhoneNumber(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: '' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: 'Invalid phone number (e.g., (123) 456-7890, 123-456-7890, 1234567890)' }));
            }
        } else if (name === 'email') {
            setEmail(value);
            if (validateEmail(value)) {
                setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, email: 'Invalid email address' }));
            }
        }
    };

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        if (name === 'agreedToTerms') {
            setAgreedToTerms(!agreedToTerms);
        } else if (name === 'agreedToCommunications') {
            setAgreedToCommunications(!agreedToCommunications);
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!validatePhoneNumber(phoneNumber)) formErrors.phoneNumber = 'Phone number is invalid (e.g., (123) 456-7890, 123-456-7890, 1234567890)';
        if (!phoneNumber) formErrors.phoneNumber = 'Phone number is required';
        if (!validateEmail(email)) formErrors.email = 'Email is invalid';
        if (!email) formErrors.email = 'Email is required';
        if (!agreedToTerms) formErrors.agreedToTerms = 'You must agree to the terms';
        if (!agreedToCommunications) formErrors.agreedToCommunications = 'You must consent to receive communications';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            onSubmit({ phoneNumber, email });
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white  text-center">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                {firstName}, May I Ask For Your Phone Number & Email?
            </h1>
            <p className="mb-6 text-gray-700">
                As you complete the first step of your application, please provide your phone number & email so we can easily contact you if direct communication is necessary. Additionally, ensure that you review and agree to the terms and conditions.
            </p>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="tel"
                        name="phoneNumber"
                        placeholder="Your phone number"
                        value={phoneNumber}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.phoneNumber && <span className="text-red-500 text-sm">{errors.phoneNumber}</span>}
                </div>
                <div className="mb-4">
                    <input
                        type="email"
                        name="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={handleChange}
                        className={`mt-1 block w-full px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    />
                    {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                </div>
                <div className="mb-4 text-left">
                    <div className="flex items-start">
                        <input
                            type="checkbox"
                            name="agreedToTerms"
                            checked={agreedToTerms}
                            onChange={handleCheckboxChange}
                            className="mr-2 leading-tight mt-1"
                        />
                        <span>
                            I agree to the <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="underline">Terms of Application</a> , <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="underline">Terms of Use</a> which includes an arbitration agreement, Credit Gathering Authorization, E-sign Disclosure, <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="underline">Privacy Policy</a>, and <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/credit-profile-authorization-2314" className="underline">Credit Profile Authorization</a>.
                        </span>
                    </div>
                    {errors.agreedToTerms && <span className="text-red-500 text-sm block">{errors.agreedToTerms}</span>}
                </div>
                <div className="mb-4 text-left">
                    <div className="flex items-start">
                        <input
                            type="checkbox"
                            name="agreedToCommunications"
                            checked={agreedToCommunications}
                            onChange={handleCheckboxChange}
                            className="mr-2 leading-tight mt-1"
                        />
                        <span>
                            By providing your name and contact information, you consent to receive communications from Cash Advance USA or its agents, including marketing calls and messages. Consent is not a condition of purchase. Carrier messaging and data rates may apply. You may revoke consent by calling +1 844-768-2769 or emailing info@cashadvance-usa.com.
                        </span>
                    </div>
                    {errors.agreedToCommunications && <span className="text-red-500 text-sm block">{errors.agreedToCommunications}</span>}
                </div>
                <button type="submit" className="w-full py-2 px-4 submit-btn text-white rounded-lg shadow-md  focus:outline-none">
                    Submit and proceed now
                </button>
            </form>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline"> Privacy policy</a>
            </div>
        </div>
    );
};

export default ContactForm;
