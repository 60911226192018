import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import QuestionFlow from './components/QuestionFlow';
import ThankYouPage from './components/ThankYouPage';
import useFormState from './hooks/useFormState';

const App = () => {
  const [state, updateState] = useFormState();
  const [height, setHeight] = useState(815);
  const [width, setWidth] = useState(398);

  useEffect(() => {
    function updateDimensions() {
      const targetElement = document.getElementById('surveys');
      if (targetElement) {
        const { height, width } = targetElement.getBoundingClientRect();
        setHeight(height);
        setWidth(width);
      }
    }

    // Update dimensions initially
    updateDimensions();

    // Send updated height and width to the parent window
    function sendHeightAndWidth() {
      window.parent.postMessage(
        { height: document.getElementById('surveys')?.getBoundingClientRect().height, width, location: window.location.href },
        "*"
      );
    }

    // Send height and width on mount and whenever the window is resized
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('resize', sendHeightAndWidth);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('resize', sendHeightAndWidth);
    };
  }, [height, width]);  // Dependencies: effect runs whenever height or width changes



  const shouldRedirectToThankYouPage =
    (state.typeOfBankAccount === 'personal bank account' ||
      state.businessActivity === 'Less than a year') &&
    state.phoneNumberAndEmail;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            shouldRedirectToThankYouPage ? (
              <Navigate to="/thank-you" replace />
            ) : (
              <div id='surveys' className="responsive-height grid items-center justify-center w-full">
                <QuestionFlow
                  state={state}
                  updateState={updateState}
                  onComplete={() => updateState('completed')(true)}
                />
              </div>
            )
          }
        />
        <Route path="/thank-you" element={<ThankYouPage firstName={state.firstName} officialBusinessName={state.officialBusinessName} />} />
      </Routes>
    </Router >
  );
};

export default App;
