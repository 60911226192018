import React, { useState } from 'react';
import logo from '../assets/images/Logo.png'; // Adjust the path if necessary

const FundUtilizationForm = ({ firstName, onSubmit, onGoBack }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [other, setOther] = useState('');
    const [error, setError] = useState('');

    const options = [
        'Expansion',
        'Equipment purchase',
        'Purchase a vehicle',
        'Inventory',
        'Payroll',
        'Marketing',
        'Commercial real estate',
        'Remodel my location',
        'Refinance debt',
        'Finance Accounts Receivable',
        'Buy a business/franchise',
        'Start a business',
        'Other'
    ];

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setOther(''); // Clear other input if a predefined option is selected
        setError(''); // Clear any previous error messages
        if (option !== 'Other') onSubmit(option)

    };

    const handleChange = (e) => {
        setOther(e.target.value);
        setSelectedOption('Other'); // Automatically select "Other" when typing
        setError(''); // Clear any previous error messages
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedOption === 'Other' && !other.trim()) {
            setError('Please specify how you will use the funds.');
        } else {
            onSubmit(selectedOption === 'Other' ? other : selectedOption);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white text-center shadow-lg rounded-lg">
            <img src={logo} alt="CashAdvance USA Logo" className="mx-auto mb-4 small-logo" />
            <h1 className="text-2xl font-semibold mb-4">
                {firstName}, What Would You Like To Use Your Advance On?
            </h1>
            <p className="mb-6 text-gray-700">
                We ask about fund utilization to tailor our support to your business goals effectively.
            </p>
            <div className="max-h-64 overflow-y-auto flex flex-col gap-4">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className={`submit-btn text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out focus:outline-none ${selectedOption === option ? 'ring-2 ring-blue-300' : ''}`}
                        onClick={() => handleOptionSelect(option)}
                        aria-label={`Select ${option}`}
                    >
                        {option}
                    </button>
                ))}
            </div>
            {selectedOption === 'Other' && (
                <div className="mt-4">
                    <p className="mb-2 text-left">Please Specify</p>
                    <input
                        type="text"
                        name="other"
                        placeholder="Enter fund utilization"
                        value={other}
                        onChange={handleChange}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            )}
            {error && <span className="text-red-500 text-sm">{error}</span>}
            <div className="mt-6 flex gap-4">
                <button
                    onClick={handleSubmit}
                    disabled={!selectedOption && !other.trim()}
                    className={`flex-1 py-2 px-4 ${selectedOption || other.trim() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 cursor-not-allowed'} text-white rounded-lg shadow-md focus:outline-none`}
                >
                    Go Next
                </button>
                <button
                    onClick={onGoBack}
                    className="flex-1 py-2 px-4 bg-gray-200 text-gray-700 rounded-lg shadow-md hover:bg-gray-300 focus:outline-none"
                >
                    Go Back
                </button>
            </div>
            <div className="mt-6 text-sm">
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/terms-and-conditions-2-6617" className="terms-policy-btn-color hover:underline">Terms of Use</a> ·
                <a target="_blank" rel="noopener noreferrer" href="https://cashadvance-usa.com/privacy-policy907306-3708" className="terms-policy-btn-color hover:underline">Privacy Policy</a>
            </div>
        </div>
    );
};

export default FundUtilizationForm;
